/* eslint-disable @typescript-eslint/no-explicit-any */
import config from '../config';
import type { Logger } from 'cloudwatch-front-logger';
import { getVisitorId } from './visitor';
import StackTrace from 'stacktrace-js';
import type { EventPerfDetails } from './logPerfHelper';

declare global {
  interface Window {
    __ewLogAreas?: string[]; // Enabled debug area (eg. window.__ewLogAreas = ['CHAT'])
  }
}

const disabledAreas: string[] = ['SESSIONS', 'JIT', 'PLAY', 'CHAT', 'ANIM', 'RESIZE'];
const cloudLoggedAreas: string[] = ['PLAY'];

let cwLogger: Logger | null = null;

const installCwLogger = async () => {
  try {
    const { Logger } = await import('cloudwatch-front-logger');

    cwLogger = new Logger(
      config.AWS_LOGGER_ACCESS_KEY,
      config.AWS_LOGGER_SECRET_KEY,
      config.AWS_LOGGER_REGION,
      `frontend-${config.isProduction ? 'prod' : config.env}`
    );

    cwLogger.install({
      logStreamNameResolver(): string {
        return getVisitorId() ?? 'anonymous';
      },
      async messageFormatter(e, info = { type: 'unknown' }) {
        if (!e.message) {
          return null;
        }

        let stack = null;
        if (e.stack) {
          stack = e.stack;
          try {
            stack = await StackTrace.fromError(e, { offline: true });
          } catch {
            // Do nothing
          }
        }

        return JSON.stringify({
          message: e.message,
          visitorId: getVisitorId(),
          timestamp: new Date().getTime(),
          userAgent: typeof window === 'undefined' ? undefined : window.navigator.userAgent,
          stack,
          ...info,
        });
      },
    });
  } catch (e) {
    cwLogger = null;
    console.error('Error setting up cloudwatch logger', e);
  }
};

// Flip to !config.isLocal if not testing
if (!config.isSSR && !config.isLocal) {
  void installCwLogger();
}

type LoggerFunc = (type: string, ...args: any[]) => Promise<void> | undefined;
type LoggerFuncPerf = (perfDetails: EventPerfDetails) => void;

export const logger = (function (): {
  sendLogs: () => Promise<void>;
  debug: LoggerFunc;
  info: LoggerFunc;
  warn: LoggerFunc;
  error: LoggerFunc;
  perf: LoggerFuncPerf;
} {
  return {
    sendLogs: () => {
      return cwLogger?.onInterval() ?? Promise.resolve();
    },
    debug: (area: string, message: string, ...args: any[]): Promise<void> | undefined => {
      let allowedAreas: string[] = [];
      if (typeof window !== 'undefined' && Array.isArray(window?.__ewLogAreas)) {
        allowedAreas = window?.__ewLogAreas;
      }

      let promise = undefined;
      if (cwLogger && cloudLoggedAreas.includes(area)) {
        promise = cwLogger.onError(new Error(message), { type: 'debug', ...args });
      }

      if (disabledAreas.includes(area) && !allowedAreas.includes(area)) {
        return promise;
      }
      console.debug(`@${area} `, message, ...args);
      return promise;
    },
    info: (type: string, message: string, ...args: any[]): Promise<void> | undefined => {
      console.info(type, ' ', message, ...args);
      return cwLogger?.onError(new Error(message), { type, ...args });
    },
    warn: (type: string, message: string, ...args: any[]): Promise<void> | undefined => {
      console.warn(type, ' ', message, ...args);
      return cwLogger?.onError(new Error(message), { type, ...args });
    },
    error: (type: string, message: string, ...args: any[]): Promise<void> | undefined => {
      console.error(type, ' ', message, ...args);
      return cwLogger?.onError(new Error(message), { type, ...args });
    },
    perf: (perfDetailsEnd: EventPerfDetails): Promise<void> | undefined => {
      if (perfDetailsEnd.durationMs === 0) {
        return;
      }
      const message = `PERF  ${perfDetailsEnd.type!}.${perfDetailsEnd.name!} = ${perfDetailsEnd.durationMs!}ms`;
      console.debug(message, perfDetailsEnd);
      return cwLogger?.onError(
        {
          message,
        },
        perfDetailsEnd
      );
    },
  };
})();
