import fetch from 'cross-fetch';
import merge from 'lodash/merge';
import { removeTypenameFromVariables } from '@apollo/client/link/remove-typename';
import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache } from '@apollo/client';

import config from '../config';
import typeDefs from './LocalResolver/typeDefs';
import resolvers from './LocalResolver/resolvers';
import fragmentTypes from './schema/fragmentTypes.json';

const removeTypenameLink = removeTypenameFromVariables();

const httpLink = createHttpLink({
  uri: config.GRAPHQL_SERVER_URL,
  fetch,
  fetchOptions: {
    credentials: 'include',
  },
});

export const apolloClient = new ApolloClient({
  link: ApolloLink.from([removeTypenameLink, httpLink]),
  cache: new InMemoryCache({
    possibleTypes: fragmentTypes.possibleTypes,
    typePolicies: {
      InteractionDetails: {
        merge: true,
      },
      SetSettings: {
        merge: (existing, incoming) => {
          return merge({}, existing, incoming);
        },
      },
    },
  }),
  resolvers,
  typeDefs,
});
