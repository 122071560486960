import gql from 'graphql-tag';

import { MEDIAREF } from './AssetLibrary';
import { THIRD_PARTY_INTEGRATION_FRAGMENT } from './Integrations';
import { PUBLIC_TEAM_FRAGMENT, TEAM_FRAGMENT } from './TeamFragment';
import { THEME_SETTINGS_FRAGMENT } from './ThemeSettings';
import { PRESENTER_FRAGMENT } from './Users';

export const PUBLIC_SET_SETTINGS_FRAGMENT = gql`
  fragment PublicSetSettings on SetSettings {
    pixelID
    enableUngatedOnDemand
    replaySettings {
      useRetargetingLink
      disallowSeek
    }
    accessControl {
      enableAccessControl
      type
      limitPerSession
      requireSSO
    }
    chatNotRespondedEmailTo
  }
`;

export const SOCIAL_MEDIA_TAGS_FRAGMENT = gql`
  fragment socialMediaTags on SocialMediaTags {
    title
    description
    mediaRef {
      ...MediaRef
    }
  }
  ${MEDIAREF}
`;

export const UPLOAD_STATUS_FRAGMENT = gql`
  fragment UploadStatus on UploadStatus {
    id
    uuid
    stage
    type
    progress
    done
    error
    urls {
      name
      sourceUrl
      uploadUrl
      playUrl
      uri
      vimeoId
      thumbnailMediaRef {
        ...MediaRef
      }
    }
  }
  ${MEDIAREF}
`;

export const PAGE_SECTION_FRAGMENT = gql`
  fragment PageSection on PageSection {
    active
    type
    imageWithText {
      title
      imageMediaRef {
        ...MediaRef
      }
      text
    }
    presenters {
      title
    }
    testimonials {
      testimonials {
        name
        avatarMediaRef {
          ...MediaRef
        }
        description
      }
    }
    logoAndTeaser {
      teaser
      ctaTopBtnText
    }
    imageBackground {
      imageMediaRef {
        ...MediaRef
      }
      title
      text
      overlayColor
      textColor
      blur
      minHeight
    }
  }
  ${MEDIAREF}
`;

export const REGISTRATION_FORM_FIELD_FRAGMENT = gql`
  fragment RegistrationFormField on RegistrationFormField {
    fieldName
    propertyName
    type
    subType
    isRequired
    isRemovable
    note
    options {
      text
      value
    }
  }
`;

export const REGISTRATION_FORM_SETTING_FRAGMENT = gql`
  fragment RegistrationFormSettings on RegistrationFormSettings {
    title
    fields {
      ...RegistrationFormField
    }
    fullNameField {
      ...RegistrationFormField
    }
    useFullNameField
    collectedFormFieldsConsent
    showConsentCheckbox
    consentCheckboxText
    registerButton
  }
  ${REGISTRATION_FORM_FIELD_FRAGMENT}
`;

export const SCHEDULED_WEBINAR_FRAGMENT = gql`
  fragment ScheduledWebinar on ScheduledWebinar {
    recurringType
    onWeekDays
    atMinutes
    atDate
    nthDayOfMonth
  }
`;

export const SCHEDULE_SETTINGS_FRAGMENT = gql`
  fragment scheduleSettings on ScheduleSettings {
    schedules {
      ...ScheduledWebinar
    }
    numberOfSessions
    showOndemandSession
    showReplaySession
    justInTimeIntervalMinutes
    justInTimeHoursOfOperations {
      day
      times
    }
    justInTimeWeekDays
    hoursOfOperationTimezone
    blackoutPeriods {
      name
      startDay
      endDay
      rule
      country
    }
    blackoutHolidays {
      startDate
      endDate
      year
    }
  }
  ${SCHEDULED_WEBINAR_FRAGMENT}
`;

export const PUBLIC_EWEBINAR_FRAGMENT = gql`
  fragment PublicEWebinar on EWebinar {
    id
    title
    duration
    vimeoVideoId
    vimeoTextTrackId
    thumbnail
    videoPlayUrl
    waitingRoomDurationSecs
    exitRoomDurationSecs
    isPublished
    logoMediaRef {
      ...MediaRef
    }
    favIconMediaRef {
      ...MediaRef
    }
    themeSettings {
      ...ThemeSettings
    }
    timeZone
    startDate
    endDateEnabled
    endDate
    justInTimeModeEnabled
    presenters {
      ...Presenter
    }
    notificationSettings {
      fromName
      fromEmail
    }
    registrationPageSettings {
      customRegistrationUrl
      footerSection {
        tagline
        disclaimer
      }
      sections {
        ...PageSection
      }
      widgetConfig {
        ctaBtnText
        ctaBtnBgColor
      }
      specifySocialTags
      socialMediaTags {
        ...socialMediaTags
      }
    }
    registrationFormSettings {
      ...RegistrationFormSettings
    }
    thankyouPageSettings {
      redirectionUrl
      packAttendeeData
      skipForReplayAndJIT
      justInTimeButtonText
      replayButtonText
      addToCalendarButtonText
      sections {
        ...PageSection
      }
      footerSection {
        tagline
        disclaimer
      }
    }
    scheduleType
    scheduleSettings {
      ...scheduleSettings
    }
    enableChat
    emailBeforeSession
    isDisabledChat
    chatSettings {
      emailOnChatReceive
      smsOnChatReceive
      nameRepliesAsChatHost
      sendInquiriesTo
      Scheduled {
        type
        automatedDetails {
          on
          message
        }
      }
      JustInTime {
        type
        automatedDetails {
          on
          message
        }
      }
      Replay {
        type
        automatedDetails {
          on
          message
        }
      }
      automatedMessages {
        AutoResponder {
          on
          message
          respondInMins
        }
        EmailCapture
      }
    }
    viewerRoomSettings {
      attendeeCounter
      attendeeCounterOption
      attendeeReactions
      attendeeReactionsOption
      attendeeReactionsDays
      language
      enableVideoPlaybackSpeed
      enableVideoPause
      videoPauseSecs
      enableVideoTimer
    }
    exitRoomSettings {
      exitRoomDisplayType
      exitRoomCTA {
        name
        link
      }
      redirectAfterExit
      redirectLink
      redirectLinkWhenMissed
      postExitRoomCTA {
        name
        link
        description
      }
    }
    uploadStatus {
      ...UploadStatus
    }
    team {
      ...PublicTeam
    }
    set {
      id
      urls {
        setUrl
        shortUrl
        registration
        replayRegistration
      }
      setSettings {
        ...PublicSetSettings
      }
    }
    presenterIds
    fullSessions {
      session
      status
    }
    isExternalHost
    thirdPartyIntegration {
      ...ThirdPartyIntegration
    }
  }
  ${SOCIAL_MEDIA_TAGS_FRAGMENT}
  ${PRESENTER_FRAGMENT}
  ${PUBLIC_TEAM_FRAGMENT}
  ${UPLOAD_STATUS_FRAGMENT}
  ${PAGE_SECTION_FRAGMENT}
  ${REGISTRATION_FORM_SETTING_FRAGMENT}
  ${SCHEDULE_SETTINGS_FRAGMENT}
  ${PUBLIC_SET_SETTINGS_FRAGMENT}
  ${THIRD_PARTY_INTEGRATION_FRAGMENT}
  ${THEME_SETTINGS_FRAGMENT}
`;

export const EWEBINAR_FRAGMENT = gql`
  fragment EWebinar on EWebinar {
    ...PublicEWebinar
    isVideoSharedWithTemplate
    slug
    sessions {
      type
      date
      value
      text
    }
    team {
      ...Team
    }
    notificationSettings {
      fromName
      fromEmail
    }
    uploadStatus {
      ...UploadStatus
    }
    updatedAt
    isExternalHost
  }
  ${PUBLIC_EWEBINAR_FRAGMENT}
  ${TEAM_FRAGMENT}
`;
